<script setup lang="ts">
defineEmits<{
  completed: [completed: boolean]
}>()

const model = defineModel<boolean>()

function toggle() {
  model.value = !model.value
}
</script>

<template>
  <UiButton variant="outline" label="Mark as complete" class="justify-between" @click="toggle">
    <template #trailing>
      <UiToggle :model-value="model" color="gray" @click.stop="toggle" />
    </template>
  </UiButton>
</template>
