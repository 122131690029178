<script lang="ts" setup>
import type { TDContextResolved } from '#td-next/composables/context'
import type { ProfileDetails, Section, SubSection } from '@forgd/contract/types'
import TdDevCommandPalette from '#td-next/components/designer/TdDevCommandPalette.vue'
import TdHeroCard from '#td-next/components/designer/TdHeroCard.vue'
import TdMarkComplete from '#td-next/components/designer/TdMarkComplete.vue'
import { isTdFormDirty } from '#td-next/composables/form'
import { tdSections, useTD } from '#td-next/state/useTD'
import { createTemplatePromise } from '@vueuse/core'

const route = useRoute()
const auth = useAuth()
const td = useTD()
await td.fetchTD()
const activeLink = ref(route.path)
function setActive(path: string) {
  activeLink.value = path
}

const footerCta = 'Contact us for troubleshooting or tips on how to utilize our tools.'
const footerCtaButtonText = 'Contact Support'

// make sure they have selected a template before accessing other pages
if (!td.tokenDesigner?.template && route.path !== '/token-designer') {
  navigateTo('/token-designer')
}

const tokenProfileDetails = computed<ProfileDetails>(() => {
  const tokenProfile = tdSections.value?.find(s => s.slug === 'token-profile')
  const basicInformation = tokenProfile?.subSections?.find(f => f.slug === 'basic-information')
  const profileDetails = basicInformation?.fields.find(f => f.slug === 'profile-details')
  const values = profileDetails?.values as ProfileDetails
  return {
    name: values?.name || auth.project?.name || '',
    ticker: values?.ticker || auth.project?.ticker || '',
    website: values?.website || auth.project?.link || '',
    image: values?.image || auth.project?.image || '',
  }
})

interface SubSectionWithTitle extends SubSection {
  title?: string
  resources?: { path: string, title: string }[]
}

const currentSection = ref<Section | null>(null)
const currentSubSection = ref<SubSectionWithTitle | null>(null)

// TODO td context should be reactive if possible
function resolveTdContext() {
  // route path does not get flushed at the correct time
  const path = window.location.pathname
  const [sectionSlug, subSectionSlug] = path.split('/').slice(-2)
  const section = tdSections.value?.find(s => s.slug === sectionSlug)
  const subSection = section?.subSections.find(
    s => s.slug === subSectionSlug,
  )
  currentSection.value = section ?? null
  currentSubSection.value = subSection ?? null
}

onMounted(() => {
  resolveTdContext()
})

const toast = useAppToast()
const isMarkingCompleteInProgress = ref(false)

provide('resolveTdContext', resolveTdContext)

provide<() => Promise<TDContextResolved>>('tdContext', async () => {
  await resolveTdContext()
  return {
    tokenProfileDetails,
    currentSection,
    currentSubSection,
    fields(slug: string) {
      return currentSubSection.value?.fields.find(f => f.slug === slug)?.values
    },
  }
})

useHead({
  title: () => (currentSubSection.value?.title || currentSubSection.value?.label) ?? 'Untitled',
  titleTemplate: '%s %separator Token Designer',
  templateParams: {
    separator: '-',
  },
})

const surroundBy = computed(() => {
  // using the nav list, we should figure out what the prev / next page is, if the index is 0 we don't show prev
  // we need to check children only
  const children = td.nav.flatMap(item => item.children)
  const index = children.findIndex(item => item.to === route.path)
  // handle being at the start or end of the items
  if (index === 0) {
    return [null, children[index + 1]]
  }
  else if (index === children.length - 1) {
    return [children[index - 1], null]
  }
  else {
    return [children[index - 1], children[index + 1]]
  }
})

async function saveTdForm() {
  const nuxtApp = useNuxtApp()
  await nuxtApp.hooks.callHook('forgd:td-form-submission')
}

const selectedTemplate = computed(() => {
  return !!td.tokenDesigner?.template
})

const { environment } = useRuntimeConfig()

async function markAsComplete(isCompleted: boolean) {
  isMarkingCompleteInProgress.value = true
  const subSectionSlug = td.context.currentSubSection?.slug
  if (!subSectionSlug) {
    toast.error({
      title: 'Something went wrong!',
      description: 'We were not able to process your request. Please try again.',
    })
    isMarkingCompleteInProgress.value = false
    return
  }

  try {
    if (!auth.project?.id) {
      throw new Error('Project ID not found')
    }

    const response = await $core('token-designer/token-profile/{projectId}/update-mark-as-completed', {
      method: 'POST',
      path: {
        projectId: auth.project?.id,
      },
      body: { subSectionSlug, completed: isCompleted },
    })
    if (response && response.completed !== undefined && td.context.currentSubSection) {
      td.context.currentSubSection.completed = response.completed
      isMarkingCompleteInProgress.value = false
    }
  }
  catch (e) {
    toast.error({
      title: 'Something went wrong!',
      description: `We were not able to process your request. Please try again. More info: ${e}`,
    })
    isMarkingCompleteInProgress.value = false
  }
}

/**
 * Navigation guard to warn against losing unsaved changes
 */
const showConfirmNavigationModal = ref(false)
const ConfirmNavigationPromise = createTemplatePromise<boolean>()
addRouteMiddleware(async (to, from) => {
  if (from.path.startsWith('/token-designer/') && isTdFormDirty.value) {
    showConfirmNavigationModal.value = true
    const confirmed = await ConfirmNavigationPromise.start()
    if (confirmed) {
      isTdFormDirty.value = false
    }
    else {
      return false
    }
  }
  showConfirmNavigationModal.value = false
  return true
})
</script>

<template>
  <div>
    <NuxtLoadingIndicator />
    <NuxtLayout name="dashboard" :cta="footerCta" :cta-btn-text="footerCtaButtonText">
      <template #header>
        <div class="flex gap-2 items-center">
          <img src="/app/illustrations/tokenomics-v2-icon.svg" alt="Token Designer Icon" width="32" height="32">
          <div class="text-sm font-semibold flex-shrink-0">
            Token Designer
          </div>
          
        </div>
      </template>

      <template v-if="selectedTemplate" #sidebar>
        <div class="h-full border-r border-forgd-bgd-600 min-w-[230px]">
          <nav
            aria-label="Token Designer Navigation"
            class="py-4 px-2 flex-shrink-0 sticky top-0"
          >
            <UiButton
              variant="ghost"
              size="sm"
              active-class="bg-forgd-bgd-400"
              to="/token-designer/"
              label="Dashboard"
              class="w-full justify-start"
            />
            <UAccordion :key="td.currentSubMenu" :items="td.nav" class="flex-col gap-2 mt-2">
              <template #default="{ item, open }">
                <UiButton
                  variant="ghost"
                  size="sm"
                  :label="item.label"
                  class="w-full justify-between"
                >
                  <template #trailing>
                    <UiIcon
                      name="i-heroicons-chevron-down-20-solid"
                      :class="[open && 'rotate-180']"
                    />
                  </template>
                </UiButton>
              </template>
              <template #item="{ item }">
                <ul class="space-y-1">
                  <li
                    v-for="(child, key) in item.children"
                    :key="key"
                  >
                    <UiButton
                      variant="ghost"
                      size="sm"
                      :to="child.children ? undefined : child.to"
                      class="flex-row-reverse justify-end w-full gap-2 font-light"
                      :label="child.label"
                      :class="{ 'bg-forgd-bgd-400': activeLink === child.to }"
                      @click="setActive(child.to)"
                    >
                      <template #trailing>
                        <UiIcon
                          :name="child.icon"
                          class="w-3.5 h-3.5 text--bgd-200 flex-shrink-0 "
                          :class="{ 'text-green-600': child.completed }"
                        />
                      </template>
                    </UiButton>
                  </li>
                </ul>
              </template>
            </UAccordion>
          </nav>
        </div>
      </template>
      <div>
        <div>
          <!-- top content -->
          <template v-if="td.context.currentSubSection">
            <TdHeroCard>
              <template #title>
                {{ td.context.currentSubSection.label }}
              </template>
              <template #body>
                <div v-html="td.context.subSectionMeta.description" />
              </template>
              <template #actions>
                <div class="flex flex-end flex-col gap-4">
                  <TdMarkComplete
                    :loading="isMarkingCompleteInProgress"
                    :model-value="td.context.currentSubSection.completed"
                    class="bg-neutral-600/40 border-neutral-600/40"
                    @update:model-value="markAsComplete"
                  />
                  <AcademySlideoverButton
                    v-if="td.context.currentSubSection?.resources?.length"
                    :path="td.context.subSectionMeta.resources[0].path"
                    class="bg-forgd-green-600 hover:bg-forgd-green-700"
                    icon="i-heroicons-book-open"
                    :slideover="{ title: td.context.subSectionMeta.resources[0].title }"
                  />
                </div>
              </template>
            </TdHeroCard>
            <UiRule class="my-8" />
          </template>

          <!-- main content -->
          <div class="my-5">
            <slot />
          </div>
        </div>
        <UiRule v-if="$route.name !== 'token-designer'" class="my-8" />

        <!-- bottom navigation -->
        <div
          v-if="$route.name !== 'token-designer'"
          class="mb-8 flex justify-between items-center"
        >
          <UiButton
            variant="solid"
            class="w-[260px] h-[60px] justify-center"
            :loading="isTdFormSaving"
            :disabled="!isTdFormValid || !isTdFormDirty"
            label="Save & Commit"
            @click="saveTdForm"
          />
          <nav aria-label="Prev / Next Pages">
            <div
              class="flex justify-center items-center h-[60px] gap-8 py-2 border rounded-xl border-forgd-bgd-600"
            >
              <NuxtLink
                v-if="surroundBy[0]"
                :to="surroundBy[0].to"
                class="text-forgd-primary-900 w-[260px] group flex flex-col justify-end items-end"
                :class="{ 'pr-8': !surroundBy[1] }"
              >
                <div class="flex gap-1 group-hover:gap-3 transition-all">
                  <UIcon name="i-heroicons-arrow-left" class="w-5 h-5" />
                  <div class="text-sm font-semibold">
                    Prev
                  </div>
                </div>
                <div class="text-gray-500 text-sm group-hover:underline">
                  {{ surroundBy[0].label }}
                </div>
              </NuxtLink>
              <div
                v-if="surroundBy[0] && surroundBy[1]"
                class="flex min-h-full h-full w-[1px] bg-forgd-bgd-600"
              />
              <NuxtLink
                v-if="surroundBy[1]"
                :to="surroundBy[1].to"
                class="text-forgd-primary-900 w-[260px] group flex flex-col justify-start items-start"
                :class="{ 'pl-8': !surroundBy[0] }"
              >
                <div
                  class="flex items-center gap-1 group-hover:gap-2 transition-all"
                >
                  <div class="text-sm font-semibold">
                    Next
                  </div>
                  <UIcon name="i-heroicons-arrow-right" class="w-5 h-5" />
                </div>
                <div class="text-gray-500 text-sm group-hover:underline">
                  {{ surroundBy[1].label }}
                </div>
              </NuxtLink>
            </div>
          </nav>
        </div>
      </div>
    </NuxtLayout>
    <ConfirmNavigationPromise v-slot="{ resolve }">
      <UiModal v-model="showConfirmNavigationModal" prevent-close>
        <UiCard>
          <div class="relative">
            <div class="absolute top-5 right-5">
              <UIcon name="i-heroicons-x" class="w-6 h-6 text-forgd-primary-900" @click="resolve(false)" />
            </div>
            <div class="p-5 flex flex-col items-center font-display">
              <div class="p-5 flex flex-col items-center gap-2.5 text-center">
                <UIcon
                  name="i-heroicons-exclaimation-circle"
                  class="text-forgd-primary-300 w-10 h-10"
                />
                <div class="text-xl text-forgd-primary-900 font-semibold">
                  Are you sure you want to leave this page without saving your latest changes?
                </div>
                <p class="text-sm text-forgd-gray-600">
                  All your progress will be lost. We recommend closing this modal and saving your progress before going to a different page.
                </p>
              </div>
              <div class="grid grid-cols-2 gap-4 w-full">
                <UiButton size="xl" color="white" class="w-full" @click="resolve(true)">
                  Leave without Saving
                </UiButton>
                <UiButton size="lg" color="primary" class="w-full" @click="resolve(false)">
                  Cancel
                </UiButton>
              </div>
            </div>
          </div>
        </UiCard>
      </UiModal>
    </ConfirmNavigationPromise>
    <TdDevCommandPalette v-if="environment !== 'production'" />
  </div>
</template>

<style>
:root {
  --vis-font-family: 'DM Mono';
  --vis-axis-font-family: 'DM Mono';
  --vis-axis-label-color: #95958D;
  --vis-axis-domain-color: #C7C7BE;
  --vis-axis-domain-line-width: 1px;
  --vis-axis-label-font-size: 10px;
  --vis-axis-tick-label-color: #95958D;
  --vis-axis-tick-label-font-size: 10px;
  --vis-tooltip-background-color: #EDEDEA;
  --vis-tooltip-border-color: #C7C7BE;
  --vis-tooltip-text-color: #001221;
  --vis-tooltip-font-size: 12px;
  --vis-tooltip-shadow-color: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.20);
  --vis-tooltip-padding: 4px 12px;
}
</style>
